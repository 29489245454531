<template>
  <div class="index-box">
      <div class="top-box">
         <div class="top-banner"></div>
          <!-- <img class="top-banner" src="../../assets/indexImg/top-banner.png" alt="" srcset=""> -->
          <div class="one-box index">
            <h3 class="title"><span>智能工厂应用全景</span><img src="../../assets/indexImg/title-one.png" alt="" srcset=""></h3>
             <div class="img-box">
              <img src="../../assets/indexImg/one.png" alt="" srcset="" style="margin-left:-140px;">
                <div class="txt-box">
                  <div class="txt-item" v-for="(item,num) in txtList" :key="num">
                      <div class="simple bgStyle">
                        <h3 class="name-box"> 
                            <IconFont v-if="item.icon" class="iconstyle" :type="item.icon" />
                            <span class="" >{{item.name}}</span></h3>
                      </div>
                      <div class="all bgStyle">
                        <p v-for="(txtItem,index) in item.txt" :key="index">
                          {{txtItem}}
                        </p>
                      </div>
                  </div>
                </div>
             </div>
          </div>
         <div class="case-box index">
           <h3 class="title title2"><span>解决方案</span><img src="../../assets/indexImg/title-two.png" alt="" srcset=""></h3>
           <div>
             <ul>
               <li v-for="(item,index) in solutionList"  :key="index" v-show="index<3">
                 <h4>{{item.name}}</h4>
                 <p>{{item.txt}}</p>
                <router-link class="btn-ljgd btn-ljgd3" :to="`/BusinessPage/${item.id}`">了解更多<span></span></router-link>
               </li>
             </ul>
           </div>
        </div>
         <div class="new-box index">
           <h3 class="title title2"><span>最新动态</span><img src="../../assets/indexImg/title-three.png" alt="" srcset=""></h3>
           <div class="new">
             <a-row>
                <a-col :span="8" >
                  <div v-for="(item,index) in newList"  :key="index" style=""  v-show="index==0" class="rigth">
                            <div class="layer"></div>
                            <router-link class="link-hover"  :to="`/NewDetails/${item.newsID}`">
                                <img class="slide-img" :src="newsImg+item.newsImageUrl" alt="图片">
                                <div class="subtitle">{{item.newsTitle}}</div>
                            </router-link>
                  </div>
                </a-col>
                <a-col :span="16" >
                     <div v-for="(item,index) in newList"  :key="index" style=""   class="left" v-show="index>0&&index<4"> 
                            <router-link class="link-hover"  :to="`/NewDetails/${item.newsID}`">
                                <div class="time">{{moment(item.updatedTime).format('MM/DD')}}</div>
                                <div class="txt">
                                    <div class="subtitle">{{item.newsTitle}}</div>
                                    <div class="desc" style="-webkit-box-orient: vertical;">{{item.newsAbstract}}</div>
                                </div>
                            </router-link>
                        </div>
                </a-col>
              </a-row>
           </div>
        </div>
        <div class="message index">
          <h3 class="title"><span>产品优势</span><img src="../../assets/indexImg/title-four.png" alt="" srcset=""></h3>
         <div class="box">
              <a-row>
                <a-col :span="20" >
                  <div class="rigth">
                    <span class="name">郑大智能</span>
                    <div class="des">
                       <p>
                          <a-icon type="check-circle" theme="filled" />
                          贴合实际生产场景，9个功能模块打造数字化工厂！
                        </p>
                       <p>
                          <a-icon type="check-circle"  theme="filled" />解决工厂实际痛点 
                          <a-icon type="check-circle"  theme="filled" />生产数据实时、透明，让管理、决策有据可依!
                          <a-icon type="check-circle"  theme="filled" />提高生产管理效率</p>
                    </div>
                  </div>
                </a-col>
                <a-col :span="4" >
                     <div class="left"> 
                          <a href="javascript:void(0);" class="more"  @click="showModal()">立即体验</a>
                      </div>
                </a-col>
              </a-row>
         </div>
        </div>
      </div>
      <div class="center-box"></div>
      <div class="bottom-box">
        <img class="bottom-box-img" src="../../assets/indexImg/bottom.png" alt="" srcset="">
      </div>
      <modal :visible="visible" @hideModal="hideModal()" />
  </div>
</template>
<script>
import { getIndex } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
import modal from'./components/modal'
import moment from 'moment'
import IconFont from'@/components/Icon'
export default {
  components: {
    IconFont,
    modal
  },
  data () {
    return {
      visible:false,
      productList:[],
      solutionList:[],
      newList:[],
      newsImg,
       txtList:[
              {
                name:'物料管理',
                icon:'icon-wuliaoguanli',
                txt:['物料库存数据实时监控，避免物料呆滞和浪费','库存阈值设定，水位波动快速预警，提高库存利用率','透明化管理生产物料，出入库信息自动记录，库存流转准确查询','物料、库存数据可视化分析，提升物料周转效率']
              },
              {
                name:'计划管理',
                icon:'icon-jihuaguanli',
                txt:['生产订单在线同步，快捷制定生产计划，一键下发生产任务 ','根据生产资源优化排程，提升排程效率','准确应对插单、急单等突发情况，动态调整生产计划','多维度计划分析，实时查看排程情况']
              },
              {
                name:'异常管理',
                icon:'icon-yichangguanli',
                txt:['生产、设备、库存等异常实时报警，轻松应对','准确提醒订单漏排、报工逾期、交期临近等状态，降低交付风险','所有异常情况实时记录，智能分析推送，帮助管理者提升工厂运营平稳性']
               },
               {
                name:'设备管理',
                icon:'icon-shebeiguanli',
                txt:['设备运行数据实时采集、状态变化实时监控','设备台账电子化、规范化保存，方便追溯分析','自定义维保、点检任务，周期性提醒派单，完成报告自动上传','建立设备故障履历，为故障处理人员提供方案，缩短停工时间，提高设备利用率','设备数据多维度分析，降低设备故障率，提升设备综合效率（OEE）']
              },
             
              {
                name:'追溯管理',
                icon:'icon-zhuisuguanli',
                txt:['物料、批次、质量、设备等多重追溯，清晰准确查看所有操作流程','支持正向、反向追溯，高效打造透明工厂','所有追溯数据、报告便捷管理，一键下载打印']
              },
               {
                name:'生产管理',
                icon:'icon-shengchanguanli',
                txt:['工单在线生产，生产任务准确分配，移动端快捷领取','自动或扫码报工，生产任务完成情况实时可见，提高生产效率','生产数据实时统计，可视化多维度分析，实时掌握一手车间数据']
              },
              {
                 name:'质量管理',
                icon:'icon-zhiliangguanli',
                txt:['质量控制所需数据自动在线采集，产品质量档案详细完整','质量数据采集实时性高，反向优化条件充足','质检标准灵活配置，质量数据趋势监控，超界报警','支持产品质量SPC分析，人机料法环全方位追溯']
              },
              {
                name:'环境安全',
                icon:'icon-huanjinganquan',
                txt:['数字化EHS检查表、代码参照表，量化企业环境、健康与安全指标','EHS日常巡检，安全隐患移动端拍照上传，实时检测与评价，彻底消除各种事故','基于PDCA循环原则构建持续改善机制，多样化统计分析报表、改善效果对比显而易见']
              },
               {
                 name:'商业智能',
                icon:'icon-shangyezhineng',
                txt:['打通物料、质量、设备等所有生产信息孤岛，全流程信息化，数据看板实时监控','多维度、深层次分析生产、质量、设备等数据，报表可视化呈现，落地智能工厂','高仿真三维可视化工厂模型，快捷管理各个生产模块，真正实现协同制造']
              },
            ]
    }
  },
  mounted() {
    let that=this
    this.getData()
  },
  methods: {
      moment,
      next(){
         this.$refs.mySwiper1.swiper.slideNext();
      },
      prve(){
         this.$refs.mySwiper1.swiper.slidePrev();
      },
      hideModal(param){
          this.visible=param
      },
      showModal(){
          this.visible=true
      },
      getData(){
        let _this=this
        _this.productList=[]
        _this.solutionList=[]
        _this.newList=[]
        return getIndex().then(res => {
            if (res.state) {
                if(res.data.newsResult.length>0){
                      let newsarr=[]
                    res.data.newsResult.map((item)=>{
                         newsarr=newsarr.concat(item.newsList)
                    })
                    //按时间排序
                    _this.newList=newsarr.sort(function(a,b){
                        let result=moment(a.updatedTime).isBefore(b.updatedTime)
                        return result ? 1 : -1
                    });
                }
                if(res.data.productCaseResult.length>0){
                    res.data.productCaseResult.map((item)=>{
                        //  _this.newList=_this.newList.concat(item.newsList)
                        if(item.productType==1){
                            let imgUrl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                             _this.productList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 imgUrl:imgUrl
                             })
                        }
                        if(item.productType==2){
                              let imgurl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                              let logoUrl=item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                             _this.solutionList.push({
                                 id:item.productID,
                                 name:item.productName,
                                 txt:item.productContent,
                                 imgurl:imgurl,
                                 logoUrl:logoUrl
                             })
                        }
                    })
                }
            }
        })
      }
  },
}
</script>
<style lang="less" scoped>
.index-box{
    .title{
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        margin-bottom: 60px;
        position: relative;
    }
    .title2{
       color: #1183f1;
       margin-top: 120px;
       margin-bottom: 100px;
    }
    .title img{
      display: block;
      margin: -20px auto 0;
    }
    .index{
      position: relative;
      z-index: 6;
    }
    .top-box {
      height: 1850px;
      /* background: -webkit-gradient( linear, 0 50%, 100% 50%, from(#1183f1), to(#0744c2) ); */
      background-image: -webkit-linear-gradient(0deg, #0864dd,#0754d0, #0648c6);
      background: -ms-linear-gradient(0deg, #0864dd,#0754d0, #0648c6);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#0864dd, endColorstr=#0648c6);/*IE<9>*/
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#0864dd, endColorstr=#0648c6)";/*IE8+*/
      .top-banner{
        height: 639px;
        width: 100%;
        object-fit: cover;
        background: url(../../assets/indexImg/top-banner.png) center;
        background-size: cover;
      }
      .one-box{
        margin: 0 auto;
        width: 100%;
        text-align: center;
        .img-box{
            position: relative;
            width: 1200px;
            margin: 0 auto;
            .bgStyle{
              border-radius: 5px;
              border: 1px #2690f3 dashed;
              background-color: #fbfeff;
            }
            .txt-item {
              position: absolute;
              width: 206px;
              height: 52px;
              line-height: 50px;
              cursor: default;
              .name-box{
                font-size: 24px;
                color:#333;
                margin-bottom: 0;
                padding-bottom: 0;
                .iconstyle{
                  font-size: 32px !important;
                  margin-right: 10px;
                  vertical-align: text-top;
                }
              }
              .all {
                position: absolute;
                width: 400px;
                font-size: 13px;
                line-height: 20px;
                text-align: left;
                bottom: 0;
                right: 0;
                padding:5px 5px 2px;
                opacity:0;
                p{
                  margin-bottom: 0;
                  padding-left: 15px;
                  position: relative;
                  line-height: 25px;
                  &::before{
                    content: '';
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #2690f3;
                    position: absolute;
                    left: 5px;
                    top:8px;
                  }
                }
              }
              &:hover{
                .all{
                  opacity:1;
                  transition: all .2s;
                }
              }
            } 
            .txt-item:nth-child(1) {
             top: 64px;
             left: 180px;
            } 
            .txt-item:nth-child(2) {
              top: 264px;
              left: -60px;
              .all{
                left: 0;
                top:0;
                right: auto;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(3) {
              top: 416px;
              left: -20px;
              .all{
                left: 0;
                top:0;
                right: auto;
                bottom: auto;
              }
            }
            .txt-item:nth-child(4){
              bottom: 290px;
              left: 84px;
              .all{
                left: 0;
                top:0;
                right: auto;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(5){
                bottom: 180px;
                right:20px;
              .all{
                left: 0;
                top:0;
                right: auto;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(6){
              bottom: 300px;
              right:-50px;
              .all{
                top:0;
                left: 0;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(7){
              top: 270px;
              right: -160px;
              .all{
                top:0;
                right:0;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(8){
              top: 130px;
              right: 70px;
              .all{
                top:0;
                left:0;
                bottom: auto;
              }
            } 
            .txt-item:nth-child(9){
              top: 28px;
              right: 255px;
              .all{
                top:0;
                left:0;
                bottom: auto;
              }
            } 
          }
      }
      .case-box{
        text-align: center;
        .title2{
          margin-top: 100px;
        }
        ul{
          width: 1400px;
          overflow: hidden;
          margin:0 auto;
          min-height: 500px;
          li{
            width: 388px;
            height: 465px;
            background-color: #0f76ef;
            border-radius: 10px;
            float: left;
            margin-bottom: 20px;
            padding:50px;
            color: #fff;
            position: relative;
            h4{
              font-size: 20px;
              color:#fff;
            }
            p{
                font-size: 16px;
                text-align: left;
                margin-top:40px;
                height: 170px;
                overflow: hidden;
            }
            .btn-ljgd{
                border: 1px solid #fff;
                width: 185px;
                height: 48px;
                border-radius: 24px;
                display: block;
                position: absolute;
                bottom: 60px;
                right: 40px;
                color: #fff;
                text-align: left;
                font-size: 16px;
                line-height: 48px;
                text-indent: 30px;
                span {
                  background: url(../../assets/indexImg/arrow.png) no-repeat 30% center;
                  overflow: hidden;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  position: absolute;
                  right: 15px;
                  top: 50%;
                  margin-top: -14px;
                  transition: background-position .3s;
                  &:after {
                    border-color: #FFF;
                    border-left-color: transparent;
                  }
                  &:after {
                      content: '';
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      border: 1px solid #fff;
                      border-radius: 50%;
                      border-left-color: transparent;
                      transition: all .3s;
                      right: 1px;

                  }
                } 
                &:hover{
                  background-color: #00d4b9;
                  border-color:#00d4b9;
                }
            }
            &:nth-child(2){
              margin:0 118px;
            }
          }
        }
      }
      .new-box{
        width: 1400px;
        margin:0 auto;
        overflow: hidden;
         .new{
          padding:48px 72px 28px;
          height: 348px;
          background-color: #ffffff;
         box-shadow: 0px 3px 15px 0px rgba(166, 174, 183,.2);
          border-radius: 10px;
          .rigth{
            width: 363px;
            overflow: hidden;
            padding-bottom: 50px;
            box-sizing: border-box;
            img{
              width: 100%;
              height: 240px;
              object-fit: cover;
            }
            .subtitle{
              width: 100%;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
              color: #333333;
              margin-top: 10px;
              text-align: left;
              overflow: hidden;
            }
            &:hover{
              .subtitle{
                color:#188fff 
              }
            }
          }
          .left{
            text-align: left;
            margin-bottom: 40px;
            .subtitle{
              width: 100%;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
              color: #333333;
              margin-top: 10px;
              overflow: hidden;
              margin-bottom: 20px;
            }
            .desc{
               width: 100%;
              height: 14px;
              font-size: 14px;
              line-height: 14px;
              color: #666;
              margin-top: 10px;
              overflow: hidden;
            }
            .txt{
              padding-left: 80px;
             
            }
            .time{
              float: left;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
              font-weight: bold;
            }
             &:hover{
                .subtitle,.desc{
                  color:#188fff 
                }
              }
          }
        }
      }
      .message{
        .title{
          margin-top: 120px;
          margin-bottom: 100px;
        }
        .box{
          width:1400px; 
          margin:0 auto;
          .left{
            text-align: right;
            padding-top: 16px;
            .more{
              display: inline-block;
              width: 140px;
              height: 42px;
              background-color: #00d4b9;
              border-radius: 4px;
              color:#fff;
              text-align: center;
              line-height: 42px;
              font-size: 16px;
            }
          }
          .rigth{
            color:#fff;
            .name{
              float: left;
              height: 26px;
              font-size: 26px;
              line-height: 26px;
              padding-top: 16px;
            }
            .des{
              text-align: left;
              padding-left: 160px;
              i{
                margin-right: 10px;
                &:nth-child(3){
                  margin-left: 20px;
                }
                &:nth-child(2){
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
    .center-box {
        width: 100%;
        height:1800px;
        transform: skewY(-30deg);
        background: #f5f9fc;
        margin-top: -670px;
        position: relative;
        z-index: 2;
    }
    .bottom-box{
        width: 100%;
        height: 1380px;
        position: relative;
        z-index: 1;
        margin-top: -560px;
        background-image: -webkit-linear-gradient(-136deg, #1183f1,#0953cf);
        background: -ms-linear-gradient(-136deg, #1183f1,#0953cf);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#0953cf, endColorstr=#1183f1);/*IE<9>*/
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#0953cf, endColorstr=#1183f1)";/*IE8+*/
        position: relative;
        .bottom-box-img{
            width: 638px;
            height: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
</style>


<style lang="less" scoped>
  @media only screen  and (max-width: 1500px) {
    .top-box{
      .img-box{
        margin: -100px auto 0 !important;
        transform: scale(0.8) !important;
        .txt-item{
          .all{
            font-size:15px !important;
          }
        } 
         .bgStyle{
            border-width: 1.5px !important;
          }
      }
      .case-box{
        margin-top:-50px !important;
        .title2{
          margin-top:0 !important;
        }
        ul{
          width:1100px !important;
           li{
            width: 350px !important;
            padding:20px;
            &:nth-child(2){
              margin:0 25px  !important;
            }
          }
        }
      }
      .new-box{
        width: 1100px !important;
        margin-top:-50px !important;
        .new{
          padding: 48px 44px 28px !important;
          .left{
            padding-left:50px;  
          }
        }
      }
      .message{
        .box{
          width:1100px !important;
        }
      }
    }
    .center-box{
      height: 1500px !important;
    }
  }
</style>