<template>
  <div class="index-modal-box">
    <a-modal class="info-submit-box" :visible="visible" :closable='false' title="立即体验" @cancel='hideModal()' @ok="handleSubmit()" okText="确认" cancelText="取消" :zIndex="1000000">
        <a-form :form="form">
            <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="姓名" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageName',
                {rules: [{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
            <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="电话" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageIphone',
                {rules: [{ required: true, message: '请填写此项' },{validator: phoneVerify}]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="邮箱" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageEmail',
                {rules: [{ type: 'email',message: '格式不正确' },{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="公司" >
            <a-input
                autocomplete="off"
                v-decorator="[
                'messageCompany',
                {rules: [{ required: true, message: '请填写此项' }]}]"
                placeholder="请输入" />
            </a-form-item>
             <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="备注" >
                <a-textarea  v-decorator="[ 'messageRemark']"
                placeholder="请输入"  :autosize="{ minRows: 2, maxRows: 6 }" />
            </a-form-item>
      </a-form>
      <p style="text-align: center;text-indent: 50px;">提交完成后，我们会尽快联系您，感谢支持！</p>
    </a-modal>
  </div>
</template>
<script>
import { addMessage } from '@/api/data'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}
  export default {
    props:{
      visible:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        formItemLayout,
        form: this.$form.createForm(this),
      }
    },
    methods: {
      hideModal() {
       this.form.resetFields()
       this.$emit('hideModal',false)
      },
      phoneVerify (rule, value, callback) {
        if (value !== '' && value !== undefined) {
            if (!(/^1[3-9]\d{9}$/.test(value))) {
            callback(new Error('手机号格式不正确'))
            } else {
            callback()
            }
        }
        callback()
     },
      handleSubmit () {
        let that=this
        this.form.validateFields((errors, values) => {
          if (!errors) {
            values.companyType=2
             addMessage(values).then(res => {
                if (res.state) {
                    this.$message.success('提交成功')
                    that.hideModal()
                }else{
                    this.$message.error('提交失败')
                    that.hideModal()
                }
            }).catch(res => {
              console.log(res)
            })
          }
        })
      } 
    },
    mounted() {
    },
  };
</script>
<style lang="less">
.info-submit-box{
  .ant-modal-header{
    background: url(../../../assets/indexImg/titleBg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 24px;
    text-align: center;
    .ant-modal-title{
      color:#fff;
      font-size: 18px;
    }
  }
}
  
</style>
<style lang="css">

</style>